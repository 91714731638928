.cont-industries{
    max-width: 100%;
}

.animated-paragraph {
    font-size: 20px;
    line-height: 1.5;
    justify-content: center;
    overflow: hidden;
  }

  @media (min-width: 2560px){
    .animated-paragraph{
        font-size: 26px;
        margin-top: 4rem;
    }
  }
  