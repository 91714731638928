.container-comon{
    overflow-x: hidden;
}

.breadcrumb-active {
    font-weight: bold;
    cursor: context-menu;
    position: relative;
    padding-right: 5px;
    padding-left: 5px; 
}

.breadcrumb-active:hover {
    font-weight: 700;
    cursor: context-menu;
    color: #002447;
    text-decoration: none;
}

.breadcrumb-active:hover::after {
    content: "";
    position: absolute;
    top: 1.8em;
    left: 23px; 
    right: 88px; 
    height: 2px;
    background-color: #002447;
    z-index: -1; 
}

.breadcrumb-item + .breadcrumb-item .breadcrumb-active:hover::after {
    left: 0; 
    right: 0; 
}
.breadcrumb-active2 {
    font-weight: bold;
    cursor: context-menu;
    position: relative;
    padding-right: 5px;
    padding-left: 5px; 
}

.breadcrumb-active2:hover {
    font-weight: 700;
    cursor: context-menu;
    color: #002447;
    text-decoration: none;
}

.breadcrumb-active2:hover::after {
    content: "";
    position: absolute;
    top: 1.8em;
    left: 26px; 
    right: 50px; 
    height: 2px;
    background-color: #002447;
    z-index: -1; 
}

.breadcrumb-item + .breadcrumb-item .breadcrumb-active2:hover::after {
    left: 0; 
    right: 0; 
}

.breadcrumb-active3 {
    font-weight: bold;
    cursor: context-menu;
    position: relative;
    padding-right: 5px;
    padding-left: 5px; 
}

.breadcrumb-active3:hover {
    font-weight: 700;
    cursor: context-menu;
    color: #002447;
    text-decoration: none;
}

.breadcrumb-active3:hover::after {
    content: "";
    position: absolute;
    top: 1.8em;
    left: 26px; 
    right: 40px; 
    height: 2px;
    background-color: #002447;
    z-index: -1; 
}

.breadcrumb-item + .breadcrumb-item .breadcrumb-active:hover3::after {
    left: 0; 
    right: 0; 
}

.breadcrumb-inactive{
    cursor: pointer;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
}
.breadcrumb-inactive:hover{
    cursor: pointer;
    color: #CC3333;
    font-weight: 650;
}
.breadcrumb-inactive:hover::after {
    content: "";
    position: absolute;
    top: 1.8em;
    left: 5px; 
    right: 24px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.breadcrumb-item + .breadcrumb-item .breadcrumb-inactive:hover::after {
    left: 0; 
    right: 0; 
}

.breadcrumb-item + .breadcrumb-item::before {
    content: " > ";
    font-weight: normal;
}
