/* Initial header background */
.header-cont-bg {
  background: #e7ddb9;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15), 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Scrolled header background */
.header-cont-bg.scrolled {
  background: #1e1e1e;
  box-shadow: none;
}

/* General nav link styles */
.nav-link {
  color: black;
  position: relative;
  transition: color 0.3s ease;
  padding-bottom: 2px; /* Prevents shifting by reserving space for the border */
}

/* Active nav link styles - Stays red with a border */
.nav-link.active {
  color: #cc3333 !important;
  border-bottom: 2px solid #cc3333 !important;
}

/* Ensure active nav link does not change on hover */
.nav-link.active:hover {
  color: #cc3333 !important;
  border-bottom: 2px solid #cc3333 !important;
}

/* Hover effect on inactive links - Inactive links turn red */
.nav-link:not(.active):hover {
  /* color: #40914c; */
  color: #b41d1d;
  opacity: 1;
  border-bottom: none; 
}

/* Scrolled state for active nav link */
.nav-link.active.scrolled {
  color: white !important;
  border-bottom: 2px solid white !important;
}

/* Scrolled state for non-active links */
.header-cont-bg.scrolled .nav-link:not(.active) {
  color: white;
}

/* Hover effect for non-active links when scrolled */
.header-cont-bg.scrolled .nav-link:not(.active):hover {
  color: #eedc82;
}

/* Initial logo size and appearance */
.logo {
  height: 2.6rem;
  width: 9rem;
  margin-left: 1.3rem;
  margin-top: 2.5px;
  /* transition: all 0.3s ease; */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media (max-width:767px){
  .logo{
    margin-left: 0.5rem;
    margin-top: 2px;
  }
}
@media (min-width:768px){
  .logo{
    width: 8rem;
    margin-left: 1rem;
  }
}
/* Initial button style */
.button-header {
  background-color: #002447;
  color: white;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

/* Scrolled button style */
.scrolled-button {
  background-color: white;
  color: #002447;
  border: 1px solid #002447;
}
.scrolled-button:hover {
  background-color: #002447;
  color: white;
  border: 1px solid #002447;
}

.nav-top {
  margin-top: -10px;
}


/* Mobile Sidebar CSS*/

/* Navbar toggle button in scrolled state */
.header-nav-space {
  margin-left: 50rem;
}
.sidebar-body {
    margin-top: 0;
    border: none;
    color: black;
  }
  
  /* Sidebar body - Mobile devices only */
  @media (max-width: 767px) {
    .sidebar-body {
      background-image: linear-gradient(315deg, #000000 0%, #7f8c8d 74%);
    }
  }
.sidebar-body-header {
  margin-left: 0px;
  border: none;
}
.link-sm {
  color: black;
}
.link-sm:hover {
  color: white;
}
.section-container {
  position: relative; /* This allows the child element to be absolutely positioned */
}

.sidebar-header {
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: flex-end;
}

/* @media (min-width: 320px) {
    .sidebar-header {
      margin-left: 1px;
    }
  }
@media (min-width: 375px) {
  .sidebar-header {
    margin-left: 2.6rem;
  }
}
@media (min-width: 425px) {
  .sidebar-header {
    margin-left: 4.6rem;
  }
} */
.nav-ofcn {
  margin-top: 10px;
}
@media (max-width: 767px){
    .nav-ofcn {
        margin-top: 0px;
      }   
}

@media (min-width: 768px) {
  .nav-top {
    margin-top: -10px;
  }
}
.header-height {
  height: 3.5rem;
}
.nav-left {
  left: auto;
}
@media (min-width: 768px) {
  .nav-left {
    margin-left: -4rem;
  }
}
.active-link {
  color: red;
}
/* Default navbar toggle button (sidebar button) styles */
.navbar-toggler {
  color: black; 
  border-color: black; 
  font-weight: normal;
  background-color: transparent; 
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none; /* Removes the focus outline */
  box-shadow: none; /* Removes any additional box-shadow */
  border-color: black; /* Ensures the border stays consistent */
  font-weight: normal; /* Ensures the font weight doesn't change */
}


/* Scrolled navbar toggle button styles */
.scrolled-toggle.navbar-toggler {
  color: white !important;
  font-weight: normal;
  border-color: white !important; 
  background-color: #1e1e1e !important; 
}
/* Default navbar toggle button (sidebar button) styles */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  transition: background-image 0.3s ease;
  border: none;
}

/* Scrolled navbar toggle button styles */
.scrolled-toggle .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  border: none;
}


.mob-nav {
  color: white; 
  opacity: 1;
}

/* Active mobile nav link styles */
.active-mob-nav {
  color: #801818; 
  font-weight: 580;
}

.mob-nav-hr{
    color: white;
    opacity: 0.6;
}
.sidebar-body-header .btn-close {
    filter: brightness(0) invert(1); /* Makes the button white */
    opacity: 1; /* Ensures full opacity */
  }
  
  /* Optionally change hover state for the close button */
  .sidebar-body-header .btn-close:hover {
    filter: brightness(0.7); /* Slightly darkens on hover */
  }

  