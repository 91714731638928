.cont-bg{
    max-width: 100%;
}

.heading-bold{
    font-weight: bold;
    font-size: 28px;
}
.para-bold{
    font-weight: 700;
    font-size: 19px;
}
.divspace{
    margin-top: 3rem;
}
.heading2{
    text-align: left;
    font-size: 24px !important;
    
}
.para2{
    text-align: left;
    font-size: 18px !important;
}


/* cardgroup css  */

/* .card-group-row {
    display: flex;
    flex-wrap: wrap;
}

.card-group-row > .col {
    display: flex;
    flex-direction: column;
} */

/* .card-group-row > .col > .card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
} */

/* .card-group-row > .col > .card > .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
} */

.card-title1{
    text-align: left;
    font-size: 24px;
}
/* .card-txt1{
    text-align: left;
    margin-top: 14px;
    font-size: 18px;
} */

.card-a{
    border: none;
    height: 100%;
    margin-bottom: 30px;
}
.card-a:hover{
    background-color: rgb(235, 230, 230);
}
.para-top{
    margin-top: 1.4rem;
    font-size: 18px;
}
.heading-cont2-top{
    font-size: 24px;
    font-weight: 600;
}
.cg-te {
    display: flex;
    flex-wrap: wrap;
}

.cg-te .row {
    width: 100%;
}

/* .card-group .col {
    display: flex;
    flex: 1 0 33%; 
    padding: 15px;
} */

.card-a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: none;
    transition: background-color 0.3s ease
}
.card-a:hover {
    background-color: rgb(235, 230, 230);
}
.card-n {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: none;
    transition: background-color 0.3s ease
}
.card-n2{
    margin-top: 20px;
}
.card-x{
    margin-top: 20px;
}
.card-title2,
.card-title3 {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
}

.card-txt1,
.card-txt3 {
    text-align: left;
    margin-top: 10px;
    font-size: 18px;
}

/* @media (min-width: 2560px) {
    .card-group .col {
        padding: 15px 10px;
    }
} */

.invisible-card {
    visibility: hidden;
}


/* contact us form start */
.div-b{
    background-color: #CC3333;
    border: none;
    margin-bottom: 30px;
    height: 14%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
@media (max-width: 786px) {
    .div-b{
        height: 13.6%;
    }
}
/* @media (width: 375px) {
    .div-b{
        height: 13%;
    }
} */
.card-c{
    background-color: #CC3333;
    border: none;
    height: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}
.card-c:hover{
    background-color: #e83838;
}
.card-title-c{
    text-align: left;
    color: #D9D9D9;
    font-size: 24px;
}
.card-txt-c{
    text-align: left;
    margin-top: 16px;
    color: #D9D9D9;
    font-size: 18px;
}
.card-txt-d{
    margin-top: 10.7rem;
    text-align: left;
    color: #D9D9D9;
    font-size: 18px;
}

.contact-us-form-pos{
    margin-top: 4px;
}
.abt-fm-hding{
    text-align: left;
    color: white;
    font-size: 18px;
}
.form-cont{
    background-color: #CC3333;
    color: #D9D9D9;
    border: none; 
    border-bottom: 1px solid #D9D9D9; 
    border-radius: 0; box-shadow: none;
}
.file-upload-cont .form-cont {
    background-color: #cc3333;
    color: #d9d9d9;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: none;
    width: 100%; 
    padding: 8px 12px;
  }
  
  .file-upload-cont .atu-fm-ctrl::placeholder {
    color: white;
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .file-upload-cont .atu-fm-ctrl:hover {
    background-color: #e83838;
    color: black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-color: white;
  }
  
  .file-upload-cont .div5 {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .file-upload-cont .file-upload-row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .file-upload-cont .file-upload-col {
    flex: 1;
    padding: 0 8px; 
    margin-left: -7px;
  }


  
.div3{
    display: flex;
    flex-direction: column;
}
.div4{
    display: flex;
    align-items: flex-start;
}


.card-c:hover .atu-fm-ctrl::placeholder {
    color: white; 
  }
  
  .card-c:hover .atu-fm-ctrl {
    color: black; 
    border-color: white; 
    background-color: #e83838;
    cursor: white;
  }
  .card-c:hover .button-file {
    color: white; 
    background-color: #e83838;
  }
.button-file{
    background-color: #CC3333;
    color: white;
    border: none;
}
.checkbox{
    margin-right: 6px;
    margin-top: 21px;
}
.checkbox-txt{
    color: #D9D9D9;
    text-align: left;
    margin-left: 10px;
    margin-top: 24px;
}
/* contact-us form end */

.div6{
    display: flex;
    align-items: center;
}
.button-wide-cu{
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-left: 6.4rem;
    font-weight: 500;
    color: #002447;
    margin-top: 2rem;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 16rem;
    height: 2.4rem;
    transition: transform 0.3s ease;
}
@media (max-width:786px){
    .button-wide-cu{
        width:100%;
        padding-left:0;
        justify-content: center;
    }
}
.button-wide-cu:hover{
    background-color: #002447;
    color: white;
    transform: scale(1.05);
}

.invisible-card{
    color: white;
}
.icon-fix{
    /* padding-left: -1rem; */
}

.t-e-divb{
    background-color: #CC3333;
    border: none;
    margin-bottom: 30px;
    height: 60%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

@media (max-width: 786px) {
    .t-e-divb{
        height: 58%;
    }
}
@media (width: 2560px) {
    .t-e-divb{
        height: 55%;
    }
}