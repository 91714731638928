.cont-resource{
    max-width: 100%;
}
.res-ttl{
    text-align: left;
    font-size: 22px;
}
.res-pr{
    text-align: left;
    margin-top: 12px;
    font-size: 18px;
}
.div7{
    margin-top: 1.2rem;
}
.res-icon-cl{
    color: #CC3333;
    font-size: 25px;
    text-align: left;
    margin-right: 8px;
}
.res-pr2{
    margin-left: 4px;
    margin: 0;
    font-size: 18px;
}
.res-container {
    display: flex;
    align-items: center;
}
.card3{
    border: none;
    height: 100%;
}
.res-input{
    border: none;
}

.div7{
    display: flex;
    flex-direction: column;
}
.div8{
    display: flex;
    align-items: flex-start;
}
.form-heading{
    text-align: left;
    color: black;
    font-size: 16px;
}
.form-cont3{
    background-color: white;
    color: black;
    border: none; 
    border-bottom: 1px solid #D9D9D9; 
    /* border-radius: 0;  */
    box-shadow: none;
}
.res-fm-ctrl::placeholder {
    color: #808080;
    font-size: 18px;
    align-items: flex-start;
    border-color: black;
    padding-left: 0;
}
.res-fm-ctrl{
    padding-left: 0;
    padding-bottom: 17px;
    width: 100%;
    border-color: black;
}

.res-fm-ctrl:hover{
    background-color: white;
    color: black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-color: #707070;
}
.res-fm-hding{
    text-align: left;
    color: black;
    font-size: 18px;
}
.checkbox-container {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    margin-top: 10px;
  }
  
  .checkbox2 {
    margin: 0; /* Removes default margin */
  }
  
  .checkbox-label {
    margin: 0 0 0 8px; /* Removes default margin and adds space between the checkbox and the text */
    font-size: 18px;
  }
  
.res-fm-submit{
    border: none;
    background-color: #CC3333;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 2.8rem;
    width: 50%;
    transition: transform 0.3s ease;
    font-weight: 600;
}
@media (width: 320px){
    .res-fm-submit{
        height: 4rem;
    }
}
.res-fm-submit:hover{
    background-color: #002447;
    transform: scale(1.1);
}