.m-d-cont{
    max-width: 100%;
}
.m-d-side-img{
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.m-d-side-img-card{
    margin-top: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
@media (min-width: 1440px){
    .m-d-side-img-card{
        height: 80%;
        width: 80%;
        margin-top: 1rem;
        margin-left: 6rem;
    }
}
@media (max-width: 786px){
    .m-d-side-img-card{
        margin-top: 3rem;
        /* height: 100%; */
        width: 100%;
    }
}
.m-d-side-card{
    border: none;
    text-align: left;
    margin-top: 2rem;
}
.m-d-side-card-title{
    font-weight: 500;
    font-size: 42px;
}
.m-d-side-card-text{
    font-weight: 400;
    margin-top: 3rem;
    font-size: 22px;
    padding-right: 10px;
}
.m-d-side-card-button{
    background-color: #CC3333;
    color: #fff;
    text-align: left;
    margin-top: 2rem;
    width: auto;
    height: 3rem;
    border: none;
    font-weight: 500;
    font-size: 18px;
    padding-left: 22px;
    padding-right: 24px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: transform 0.3s ease;
} 
.m-d-side-card-button:hover{
    background-color: #002447;
    color: #fff;
    transform: scale(1.1);
}

.m-d-cont2{
    max-width: 100%;
    background: #D9D9D9;
}
.m-dev-c2-div{
    margin-top: 4rem;
}
.m-dev-c2-title{
    margin-top: 4rem;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    display: flex;
    justify-content: flex-start;
}
.m-dev-c2-text{
    margin-top: 2rem;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    display: flex;
    justify-content: flex-start;
}
.m-dev-c2-cg{
    margin-top: 2rem;
}
.m-dev-c2-cg2{
    margin-top: 0.6rem;
}

.m-dev-c2-card-col{
    margin-top: 16px;
}
.m-dev-c2-card-col-sp{
    margin-top: 16px;
}
@media (max-width: 425px){
    .m-dev-c2-card-col-sp{
        margin-top: 8px;
    }
}
.m-dev-c2-card{
    margin-top: 2rem;
    border: none;
    background: #B0B0B0;
    text-align: left;
    transition: transform 0.3s ease;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.m-dev-c2-card:hover{
    transform: scale(1.05);
}
.m-dev-c2-card-title{
    text-decoration: underline;
    margin-top: 10px;
    font-weight: bold;
    font-size: 24px;
    padding-left: 12px;
    padding-right: 12px;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 5px;
}
.m-dev-c2-card-text{
    margin-top: 2rem;
    font-size: 18px;
    padding-left: 12px;
    padding-bottom: 20px;
    padding-right: 12px;
}

.m-d-cont3{
    max-width: 100%;
    background: #ADACAC;
}
.m-dev-c3-card{
    margin-top: 2rem;
    border: none;
    background: transparent;
    text-align: left;
    transition: transform 0.3s ease;
    height: 100%;
}
.m-dev-c3-card:hover{
    transform: scale(1.05);
}
.m-dev-c3-card-title{
    margin-top: 10px;
    font-weight: bold;
    font-size: 24px;
    padding-left: 12px;
    padding-right: 12px;
}
.m-dev-c3-card-text{
    margin-top: 2rem;
    font-size: 18px;
    padding-left: 12px;
    padding-bottom: 10px;
    padding-right: 12px;
}

.m-d-cont4{
    max-width: 100%;
    background: #D9D9D9;
}
.m-d-cont4-title{
    font-size: 30px;
    font-weight: 700;
    justify-content: center;
}
.m-d-cont4-div{
    margin-top: 5rem;
}

.m-dev-c5-card{
    margin-top: 2rem;
    border: none;
    background: #B0B0B0;
    text-align: left;
    transition: transform 0.3s ease;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.m-dev-c5-card:hover{
    transform: scale(1.05);
}
.m-dev-c5-card-title{
    text-decoration: underline;
    margin-top: 10px;
    font-weight: bold;
    font-size: 24px;
    padding-left: 12px;
    padding-right: 12px;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 5px;
}
.m-dev-c5-card-text{
    margin-top: 2rem;
    font-size: 18px;
    font-weight: bold;
    padding-left: 12px;
    padding-bottom: 20px;
    padding-right: 12px;
}
.m-dev-c5-card-text-sp{
    margin-top: 2rem;
    font-size: 18px;
    font-weight: bold;
    padding-left: 12px;
    padding-bottom: 20px;
    padding-right: 12px;
}
@media (min-width: 2560px){
    .m-dev-c5-card-text-sp{
        margin-top: 4rem;
    }
}
.m-dev-c5-title2{
    margin-top: 2rem;
    font-size: 26px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;   
}
.m-dev-c5-text2{
    margin-top: 2rem;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    display: flex;
    justify-content: flex-start;   
}

.m-dev-c6-card{
    border: none;
    background: #002447;
    text-align: left;
    transition: transform 0.3s ease;
    /* margin-right: 12px;
    margin-left: 12px; */
    height: 22rem;
    width: 15rem;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
@media (max-width: 786px){
    .m-dev-c6-card{
        width: 100%;
    }
}
.m-dev-c6-card:hover{
    transform: scale(1.05);
    background-image: linear-gradient( 109.6deg,  rgba(254,253,205,1) 11.2%, rgba(163,230,255,1) 91.1% );
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    .m-dev-c6-card-title{
        color: black;
    }
}
.m-dev-c6-card2{
    border: none;
    background: #CC3333;
    text-align: left;
    transition: transform 0.3s ease;
    /* margin-right: 12px;
    margin-left: 12px; */
    height: 22rem;
    width: 15rem;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
@media (max-width: 786px){
    .m-dev-c6-card2{
        width: 100%;
    }
}

.m-dev-c6-card2:hover{
    transform: scale(1.05);
    background-image: linear-gradient( 109.6deg,  rgba(254,253,205,1) 11.2%, rgba(163,230,255,1) 91.1% );
    color: black;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    .m-dev-c6-card-title{
        color: black;
    } 
}


.m-dev-c6-card-title{
    margin-top: 14px;
    font-weight: 500;
    color: #d0d0d0;
    font-size: 26px;
    padding-left: 12px;
    padding-right: 12px;
}

.m-d-cont5{
    max-width: 100%;
    background: #00172E;
}
.m-d-cont5-div{
    margin-top: 3rem;
}
.m-d-cont5-title {
    display: flex;
    justify-content: flex-start;
    font-size: 40px;
    font-weight: 700;
    /* text-align: left; */
  }
  
  .m-d-cont5-title span:nth-child(1) {
    background: linear-gradient(to right, #5183e4, #a472b7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .m-d-cont5-title span:nth-child(2) {
    background: linear-gradient(to right, #a472b7, #cc7b89);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .m-d-cont5-title span:nth-child(3) {
    background: linear-gradient(to right, #cc7b89, #c66e76);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .m-d-cont5-title span:nth-child(4) {
    background: linear-gradient(to right, #cc7b89, #f08e9e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
.m-d-cont5-title2{
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    margin-top: 20px;
    text-align: left;
    color: #fff;
}
.m-d-cont5-button {
    background: linear-gradient(45deg, #5183e4, #a472b7, #cc7b89, #f08e9e);
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 35px;
    border: none;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    margin-top: 1.5rem;
    padding-top: 8px;
    padding-bottom: 9px;
    transition: transform 0.3s ease;
    background-size: 300% 300%; /* Adjusted size for smoothness */
    background-position: 0% 0%; /* Initial position of the background */
}

.m-d-cont5-button:hover {
    color: #fff;
    transform: scale(1.05);
    animation: wave 4s ease infinite; /* Use ease for smoother looping */
}

/* Define the wave animation */
@keyframes wave {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}


.md-cont6-title{
    text-align: center;
    margin-top: 4rem;
    font-size: 26px;
    font-weight: bold;
    color: white;
}
.md-cont6-text{
    text-align: center;
    margin-top: 1.5rem;
    font-size: 22px;
    font-weight: normal;
    color: white;
}
.md-cont6-cg{
    margin-top: 1rem;
}

.md-cont6-card{
    border: none;
    background-color: #00172E;
    color: white;
    height: 100%;
    transition: transform 0.3s ease;
}
.md-cont6-card:hover{
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transform: scale(1.05);
}
.md-c7-card-title{
    font-size: 50px;
    text-align: right;
    font-weight: bolder;
    color: white;
    margin-right: 10px;
}
.md-c7-card-text1{
    color: white;
    font-size: 20px;
    text-align: left;
    margin-top: 18px;
    font-weight: 500
}
.md-c7-card-text2{
    color: #D3D3D3;
    font-size: 20px;
    text-align: left;
    margin-top: 20px;
    padding-bottom: 12px;
}