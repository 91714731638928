.main-cont-srvs{
    max-width: 100%;
    /* background-color: #FFFBF4; */
}
.main-cont-srvs-col{
    max-width: 100%;
    height: fit-content;
}
.cont-srvs{
    max-width: 100%;
    max-height: 100%;
    background-color: #FFFBF4;
}

.para-bold2{
    font-weight: bold;
    font-size: 18px;
}
.div5{
    display: flex;
    flex-direction: column;
}
.div6{
    display: flex;
    align-items: flex-start;
}
.button2{
    width: 16rem;
    height: 2.6rem;
    background-color: #e83838;
    color: white;
    text-align: center;
    font-weight: 500;
    border: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: transform 0.3s ease;
} 
.button2:hover{
    background-color: #002447;
    color: #fff;
    transform: scale(1.1);
}
.card2{
    height: 100%;
    background-color: #646464;
    border: none;
    margin-top: 2rem;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.card2-body{
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 6rem;
    font-size: 3rem;
    font-weight: 1000;
    color: #d4d2d2;
    transform: rotate(-40deg);
    cursor: pointer;
}
@media (max-width: 425px) {
    .card2-body{
        font-size: 1.8rem;
    }
}
.file-drop-input{
    display: none;
}
.cont-wd{
    background-image: linear-gradient(to bottom, #071B23, #8E8775);
    max-width: 100%;
}
.paragraph-grid{
    text-align: left;
    margin-top: 1.1rem;
    font-size: 20px;
    font-weight: inherit;
    color: white;
}
.paragraph-grid-bold{
    text-align: left;
    margin-top: 4rem;
    font-size: 26px;
    font-weight: bold;
    color: white;
}
.paragraph2{
    text-align: left;
    margin-top: 4rem;
    font-size: 24px;
    font-weight: bold;
    color: white;
}
.paragraph3{
    text-align: left;
    margin-top: 1rem;
    font-size: 20px;
    font-weight: normal;
    color: white;
}
.card-f{
    border: none;
    /* background-image: linear-gradient(to bottom, #071b23, #8E8775); */
    background-color: transparent;
    height: 100%;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
    color: white;
}
.cardgroup-f{
    margin-top: 2rem;
}
.card-f:hover{
    /* background-color: rgb(235, 230, 230); */
    transform: scale(1.1);
}
.card-title4{
    text-align: left;
    font-size: 24px;
}
.card-title4:hover{
    text-align: left;
    position: relative;
}

.card-txt4{
    text-align: left;
    margin-top: 16px;
    font-size: 18px;
}
.cont-gd{
    background-image: linear-gradient(to bottom, #ED2C2C, #002447);
    max-width: 100%;
}
.div6{
    border: none;
}
.card-g{
    border: none;
    background-color: transparent;
    color: white;
    height: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
    transition: transform 0.3s ease;
}
.card-g:hover{
    background-image: linear-gradient(to bottom, #ED2C2C, #002447);
    transform: scale(1.1);
}
.card-title5{
    text-align: left;
    margin-bottom: 10px;
    font-size: 24px;
}
.card-txt5{
    text-align: left;
    margin-top: 20px;
    font-size: 18px;
}
.cont-bl{
    background-color: #00172E;
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
}
.paragraph4{
    text-align: left;
    margin-top: 3rem;
    font-size: 20px;
    font-weight: normal;
    color: white;
}

.card-txt6{
    color: #D0D0D0;
    font-size: 24px;
    text-align: left;
    /* justify-content: center; */
    /* margin-top: 3rem; */
    margin-bottom: 3rem;
    display: flex;
}
.div-ct6{
    height: 30vh;
    width: 30vh;
}
.card-i {
    border: none;
    background-color: #CC3333; /* Retained original background color */
    color: #D3D3D3;
    height: 22rem;
    width: 15rem;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 16px;
    transition: transform 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
    background: #CC3333; /* Ensure this is the color before hover */
}

.card-i:hover {
    background: linear-gradient(135deg, #380036, #714270, #0CBABA, #448686); 
    background-size: 300% 300%; 
    transform: scale(1.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    animation: waveEffect 8s linear infinite; 
}

@keyframes waveEffect {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}


.card-i .card-txt6 {
    position: relative;
    z-index: 1;
}

.card-i:hover .card-txt6 {
    color: white;
}


.card-h {
    border: none;
    background-color: #002447; /* Original background color */
    color: #D3D3D3;
    height: 22rem;
    width: 15rem;
    margin-bottom: 20px;
    border-radius: 16px;
    transition: transform 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
}

.card-h:hover {
    background: linear-gradient(135deg, #380036, #714270, #0CBABA, #448686); 
    background-size: 300% 300%; 
    transform: scale(1.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    animation: waveEffect 8s linear infinite; 
}

@keyframes waveEffect {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.card-h .card-txt6 {
    position: relative;
    z-index: 1;
}

.card-h:hover .card-txt6 {
    color: white;
}

@media (max-width: 786px){
    .card-i{
        width: 100%;
        height: 20rem;
    }
    .card-h{
        width: 100%;
        height: 20rem;
    }
}
.w-dev-c4-card-col{
    margin-top: 16px;
}
.w-dev-c4-card-col-sp{
    margin-top: 16px;
}
@media (max-width: 425px){
    .w-dev-c4-card-col-sp{
        margin-top: 8px;
    }
}
.wd-ch-gapcol{
    /* margin-right: 2px; */
}
.paragraph5{
    text-align: center;
    margin-top: 4rem;
    font-size: 26px;
    font-weight: bold;
    color: white;
}
.paragraph6{
    text-align: center;
    margin-top: 1.5rem;
    font-size: 20px;
    font-weight: normal;
    color: white;
}
.card-gp1{
    margin-top: 1rem;
}
.card-j{
    border: none;
    background-color: #00172E;
    color: white;
    height: 100%;
    transition: transform 0.3s ease;
}
.card-j:hover{
    background-color: #002447;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transform: scale(1.1);
}
.card-title6{
    font-size: 50px;
    text-align: right;
    font-weight: bolder;
    color: white;
    margin-right: 10px;
}
.card-txt7{
    color: white;
    font-size: 18px;
    text-align: left;
    margin-top: 18px;
    font-weight: 500
}
.card-txt8{
    color: #D3D3D3;
    font-size: 18px;
    text-align: left;
    margin-top: 10px;
}

/* contact-us form start */
.div-c{
    background-color: #e83838;
    border: none;
    margin-bottom: 30px;
    height: 35.6%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
@media (max-width: 768px) {
    .div-c{
        height: 31%;
    }
}
.card-k{
    background-color: #e83838;
    border: none;
    margin-bottom: 30px;
    margin-top: 30px;
}
.card-k:hover{
    background-color: #fa3232;
}
.card-title-c{
    text-align: left;
    color: #D9D9D9;
}
.card-txt-c{
    text-align: left;
    margin-top: 10px;
    color: #D9D9D9
}
.card-txt-d{
    margin-top: 10.7rem;
    text-align: left;
    color: #D9D9D9
}

.form-heading{
    text-align: left;
    color: white;
    font-size: 16px;
}
.form-cont2{
    background-color: #e83838;
    color: #D9D9D9;
    border: none; 
    border-bottom: 1px solid #D9D9D9; 
    border-radius: 0; box-shadow: none;
}
.form-control::placeholder {
    color: white;
    align-items: flex-start;
  }
.form-control:hover{
    background-color: #fa3232;
    color: black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-color: white;
}  
.div3{
    display: flex;
    flex-direction: column;
}
.div4{
    display: flex;
    align-items: flex-start;
}

.card-k:hover .form-control::placeholder {
    color: white; 
  }
  
  .card-k:hover .form-control {
    color: black; 
    border-color: white; 
    background-color: #fa3232;
    cursor: white;
  }
  .card-k:hover .button-file2 {
    color: white; 
    background-color: #fa3232;
  }
.button-file2{
    background-color: #e83838;
    color: white;
    border: none;
}
.checkbox{
    margin-right: 6px;
    margin-top: 21px;
}
.checkbox-txt{
    color: #D9D9D9;
    text-align: left;
    margin-left: 10px;
    margin-top: 20px;
}
.div5{
    display: flex;
    align-items: center;
}
.div6{
    display: flex;
    align-items: center;
}
.button-wide{
    background-color: white;
    display: flex;
    padding-top: 6px;
    padding-left: 6.4rem;
    font-weight: 500;
    text-align: left;
    color: #002447;
    margin-top: 2rem;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 16rem;
    height: 2.4rem;
}
.button-wide:hover{
    background-color: #002447;
    color: white;
}
/* contact-us form end */

.paragraph7{
    text-align: center;
    color: rgba(169, 169, 169, 0.957);
    font-weight: bolder;
    font-size: 2.6rem;
    margin-bottom: 6rem;
    margin-top: 10px;
}
@media (max-width: 768px) {
    .paragraph7{
        margin-bottom: 10rem;
    }
}
.paragraph8{
    text-align: left;
    margin-top: 4rem;
    font-size: 26px;
    font-weight: bold;
    color: white;
}
.paragraph9{
    text-align: left;
    margin-top: 1.5rem;
    font-size: 20px;
    font-weight: normal;
    color: white;
}

.accordion {
    width: 100%;
    background: transparent;
}

.accordion-item {
    background: #132638;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1), -2px -2px 8px rgba(0, 0, 0, 0.1);
}
.accordion-header {
    font-weight: bold;
    color: white;
}

.accordion-body {
    background: #132638;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.accordion-button {
    font-size: 20px;
    text-align: left;
    background: transparent;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: white;
}

.accordion-button:focus {
    box-shadow: none;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.accordion-button:not(.collapsed) {
    background: #132638;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: white;
    box-shadow: none;
}

.accordion-button::after {
    background-image: none;
    content: "\f078";
    font-family: fontawesome;
    width: auto;
    height: auto;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}

.paragraph4-heading{
    text-align: left;
    margin-top: 5rem;
    font-size: 28px;
    font-weight: bold;
    color: white;
}
/* .accordion:hover{
transition: transform 0.3s ease;
transform: scale(1.1);
} */
.card-g-col{
    margin-top: 0px;
}
@media (max-width:786px) {
    .card-g-col{
        margin-top: 16px;
    }
}
.service-man-img{
    height: fit-content;
    width: 60%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
/* @media (min-width: 425px){
    .service-man-img{
        margin-bottom: 3rem;
        height: 30%;
        width: 80%;
    }
} */
/* @media (max-width: 425px){
    .service-man-img{
        margin-bottom: 7rem;
        height: 30%;
        width: 80%;
    }
} */
