.pi-heading {
  text-align: left;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
}
.au-cardgroup {
  margin-top: 3.2rem;
}
.au-cards-duel {
  background-color: #e7ddb9;
  border-color: white;
  color: black;
  position: relative; 
  overflow: visible;
  height: 100%;
  transition: transform 0.3s ease;
}
.au-cards-duel:hover {
  transform: scale(1.05);
}
.au-cards-duel-b {
  background-color: #e7ddb9;
  border-color: white;
  color: black;
  margin-top: 1.8rem;
  position: relative; /* Ensure the card is positioned relative for absolute positioning of the number */
  overflow: visible;
  /* height: 100%; */
  transition: transform 0.3s ease;
}
.au-cards-duel-b:hover {
  transform: scale(1.05);
}
@media (max-width: 425px) {
  .au-cards-duel-b{
      margin-top: 4.2rem;
  }
}
.au-cards-single {
    background-color: #e7ddb9;
    border-color: white;
    color: black;
    margin-top: 7rem;
    position: relative; /* Ensure the card is positioned relative for absolute positioning of the number */
    overflow: visible;
    /* height: 100%; */
    transition: transform 0.3s ease;
  }
  .au-cards-single:hover {
    transform: scale(1.05);
  }
  @media (max-width: 425px) {
    .au-cards-single{
        margin-top: 3.2rem;
    }
  }
  .au-cards-single-b {
    background-color: #e7ddb9;
    border-color: white;
    color: black;
    margin-top: 3.6rem;
    position: relative; /* Ensure the card is positioned relative for absolute positioning of the number */
    overflow: visible;
    /* height: 100%; */
    transition: transform 0.3s ease;
  }
  .au-cards-single-b:hover {
    transform: scale(1.05);
  }
.au-card-title {
  text-align: left;
  position: relative; /* Ensure the title text is positioned relative to keep the rest of the text aligned */
  z-index: 2;
  margin-left: 2em;
  font-weight: 500;
}
.titlenumber {
  font-size: 4em; /* Adjust the size as needed */
  font-weight: 800;
  position: absolute;
  top: -0.8em; /* Adjust the value to position the number as required */
  left: -0.6em;
  z-index: 1;
}
.title-text {
  margin-left: 0.7rem;
  font-size: 22px;
  vertical-align: top; 
  display: inline-block;
  margin-top: -4px;
}
.au-card-text {
  text-align: left;
  font-weight: 500;
  margin-top: 18px;
  font-size: 18px;
}


@media (min-width: 1024px) {
  .au-cardgroup .au-col {
    padding-right: 20px; 
  }
  .au-cardgroup .au-col + .au-col {
    padding-left: 20px; 
  }
}
