.service-bm{
    display: flex;
    justify-content: flex-start;
    /* padding-left:50px; */
}
.service-1st-cont{
    max-width: 100%;
}
.service-2nd-cont{
    max-width: 100%;
}
.service-2nd-cont-bold{
    font-size: 18px;
    font-weight: bold;
}

.cardgroup-service-main{
    margin-top: 20px;
    margin-bottom: 0px;
}
@media (max-width:786px) {
    .cardgroup-service-main{
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.card-service-main{
    background-color: #002447;
    color: #FFFFFF;
    width: 100%;
    height: 38rem;
    margin-right: 20px;
    margin-top: 20px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
}
@media (max-width: 1440px) {
    .card-service-main{
        height: 28rem;
        margin-right: 5px;
    }
}
@media (max-width: 786px) {
    .card-service-main{
        height: 32rem;
        margin-right: 0px;
    }
}

.card-service-main2{
    background-color: #CC3333;
    color: #FFFFFF;
    width: 100%;
    height: 38rem;
    margin-left: 20px;
    margin-top: 20px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
}
@media (max-width: 1440px) {
    .card-service-main2{
        height: 28rem;
        margin-left: 5px;
    }
}
@media (max-width: 786px) {
    .card-service-main2{
        height: 32rem;
        margin-left: 0px;
    }
}

.csm-title{
    text-align: left;
    font-size: 24px;
    padding-left: 10px;
    padding-top: 10px;
}
@media (min-width: 2560px) {
    .csm-title{
        font-size: 30px;
        margin-top: 1rem;
        padding-left: 1.2rem;
    }
}
.csm-text{
    text-align: left;
    font-size: 18px;
    margin-top: 2rem;
    padding-right: 2.4rem;
    padding-left: 10px;
    padding-top: 10px;
}
@media (min-width: 2560px) {
    .csm-text{
    margin-top: 4rem;
    padding-right: 6rem;
    padding-left: 1.2rem;
    font-size: 22px;
    }
}

.heading-bold2{
    font-weight: bold;
    font-size: 28px;
}
.para-bold2-service-1stcont{
    font-weight: bold;
    font-size: 18px;
}