.header-cont-bg{
    background: #E7DDB9;
}
.logo{
    height: 2.6rem;
    width: 6.6rem;
    margin-left: 1.3rem;
}
/* .navtext{
    color: black;
}
.navtext:hover{
    color: #CC3333;
    font-weight: bold;
    position: relative;
}
.navtext:hover::after {
    content: "";
    position: absolute;
    top: 2.2em;
    left: 8px; 
    right: 99px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.navtext + .navtext:hover::after {
    left: 0; 
    right: 0; 
}
.navtext2{
    color: black;
}
.navtext2:hover{
    color: #CC3333;
    font-weight: bold;
    position: relative;
}
.navtext2:hover::after {
    content: "";
    position: absolute;
    top: 2.2em;
    left: 8px; 
    right: 30px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.navtext2 + .navtext2:hover::after {
    left: 0; 
    right: 0; 
}
.navtext3{
    color: black;
}
.navtext3:hover{
    color: #CC3333;
    font-weight: bold;
    position: relative;
}
.navtext3:hover::after {
    content: "";
    position: absolute;
    top: 2.2em;
    left: 8px; 
    right: 50px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.navtext3 + .navtext3:hover::after {
    left: 0; 
    right: 0; 
}
.navtext4{
    color: black;
}
.navtext4:hover{
    color: #CC3333;
    font-weight: bold;
    position: relative;
}
.navtext4:hover::after {
    content: "";
    position: absolute;
    top: 2.2em;
    left: 8px; 
    right: 52px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.navtext4 + .navtext4:hover::after {
    left: 0; 
    right: 0; 
}
.navtext5{
    color: black;
}
.navtext5:hover{
    color: #CC3333;
    font-weight: bold;
    position: relative;
}
.navtext5:hover::after {
    content: "";
    position: absolute;
    top: 2.2em;
    left: 8px; 
    right: 48px; 
    height: 2px;
    background-color: #CC3333;
    z-index: -1; 
}

.navtext5 + .navtext5:hover::after {
    left: 0; 
    right: 0; 
} */
.button-header{
    background-color: #002447;
    color: white;
    margin-left: 10px;
}
.button-header:hover{
    background-color: white;
    color: #002447;
}
/* .header-nav-space{
    margin-left: 50rem;
}
.sidebar-body{
    margin-top: 0px;
    border: none;
    background-color: #d5ebaa;
    color: black;
}
.sidebar-body-header{
    margin-left: 0px;
    border: none;
}
.link-sm{
    color: black;
}
.link-sm:hover{
    color: white;
}
.sidebar-header {
    margin-left: 10px;
}
@media (min-width: 375px) {
    .sidebar-header {
        margin-left: 2.7rem;
    }
}
@media (min-width: 425px) {
    .sidebar-header {
        margin-left: 4.5rem;
    }
}
.nav-ofcn{
    margin-top: 10px;
}
.nav-top{
    margin-top: -10px;
}
@media (min-width: 768px) {
    .nav-top{
        margin-top: -10px;
    }
}
.header-height{
    height: 3.5rem;
}
.nav-left{
    left: auto;
}
@media (min-width: 768px) {
    .nav-left{
        margin-left: -4rem;
    }
}
.active-link{
    color: red;
} */